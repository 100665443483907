<template>

  <header class="header-home-one">
    <div class="header-top">
      <div class="thm-container clearfix">
        <div class="logo pull-left">
          <a href="/"><img src="images/logo.png" style="width:110px" alt="Awesome Image" /></a>
        </div><!-- /.logo -->
        <div class="header-right-info pull-right">
          <div class="single-header-right">
            <div class="icon-box">
              <div class="inner">
                <i class="bsicon-mail-black-envelope-symbol"></i>
              </div><!-- /.inner -->
            </div><!-- /.icon-box -->
            <div class="text-box">
              <h4>е-mail:</h4>
              <p>office@ncsofteng.com</p>
            </div><!-- /.text-box -->
          </div><!-- /.single-header-right -->
          <div class="single-header-right">
            <div class="icon-box">
              <div class="inner">
                <i class="bsicon-telephone"></i>
              </div><!-- /.inner -->
            </div><!-- /.icon-box -->
            <div class="text-box">
              <h4>телефон:</h4>
              <p>+359 (2) 411 1239</p>
            </div><!-- /.text-box -->
          </div><!-- /.single-header-right -->
        </div><!-- /.header-right-info -->
      </div><!-- /.thm-container -->
    </div><!-- /.header-top -->
    <nav class="navbar navbar-default header-one-navigation stricky">
      <div class="thm-container clearfix">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target=".main-navigation"
            aria-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse main-navigation" id="main-nav-bar">


          <ul class="nav navbar-nav navigation-box">
            <li>
              <a href="/">Начало</a>
            </li>
            <li>
              <a href="/blog">Новини</a>
            </li>

          </ul>

        </div><!-- /.navbar-collapse -->
        <div class="right-side-box">
          <a href="/Contact" class="qoute-btn">Контакти</a>
        </div><!-- /.right-box-content -->
      </div><!-- /.container -->
    </nav>
  </header><!-- /.header-home-one -->

  <router-view></router-view>

  <footer class="footer-main">
    <div class="thm-container">
      <div class="row">
        <div class="col-md-12">

          <div class="footer-widget information">
            <div class="title">
              <h3>Информация</h3>
            </div><!-- /.title -->
            <p>"ЕН СИ СОФТУЕР ИНЖЕНЕРИНГ ЕООД" е българска фирма, специализирана в консултации, разработка, внедряване и
              поддръжка на софтуерни решения.</p>
            <div class="contact-info">
              <div class="single-contact-info">
                <div class="icon-box">
                  <i class="bsicon-placeholder"></i>
                </div><!-- /.icon-box -->
                <div class="text-box">
                  <h4>Офис</h4>
                  <p>България, гр. София, <br /> бул. Александър Стамболийски 101 <br /> Sofia Tower 2, ет. 3
                  </p>
                </div><!-- /.text-box -->
              </div><!-- /.single-contact-info -->

              <div class="single-contact-info">
                <div class="icon-box">
                  <i class="bsicon-telephone"></i>
                </div><!-- /.icon-box -->
                <div class="text-box">
                  <h4>Телефон</h4>
                  <p>+359 (2) 411 1239</p>
                  <br /><br />
                </div><!-- /.text-box -->
              </div><!-- /.single-contact-info -->

              <div class="single-contact-info">
                <div class="icon-box">
                  <i class="bsicon-award-star-with-olive-branches"></i>
                </div><!-- /.icon-box -->
                <div class="text-box">
                  <h4>Фирмена регистрация</h4>
                  <p>"ЕН СИ СОФТУЕР ИНЖЕНЕРИНГ" ЕООД</p>
                  <br /><br />
                </div><!-- /.text-box -->
              </div><!-- /.single-contact-info -->

            </div><!-- /.contact-info -->
          </div><!-- /.footer-widget -->

        </div>

      </div><!-- /.row -->
    </div><!-- /.thm-container -->
  </footer><!-- /.footer-main -->
  <div class="footer-bottom">
    <div class="thm-container clearfix">
      <div class="copy-text pull-left">
        <p><a href="#">NC Software Engineering</a> - Copyright 2024.</p>
      </div><!-- /.copy-text -->
      <div class="footer-menu pull-right">
        <nav>
          <ul>
            <li><a href="/">Индекс</a></li>
            <li><a href="/blog">Новини</a></li>
            <li><a href="/contact">Контакти</a></li>
          </ul>
        </nav>
      </div><!-- /.footer-menu -->
    </div><!-- /.thm-container -->
  </div><!-- /.footer-bottom -->

</template>

<script>

export default {
  name: 'App',
  components: {

  },
  mounted() {

  }
}
</script>

<style></style>
