import { createApp } from 'vue'
import App from './App.vue'


import { createWebHistory, createRouter } from 'vue-router'


const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', name: 'index', component: () => import('@/components/AppIndex.vue') },
        { path: '/blog', name: 'blog', component: () => import('@/components/AppBlog.vue') },
        { path: '/blog-opik', name: 'blog-opik', component: () => import('@/components/blog/BlogOpik.vue') },
        { path: '/contact', name: 'contact', component: () => import('@/components/AppContacts.vue') },
    ]
});


const app = createApp(App);
app.use(router);
app.mount('#app');
